<template>
  <!-- <div class="my-wrapper">
    <div class="my-list-wrapper">
      <ListItem title="手机号">
        <input type="text" slot="value" placeholder="请输入手机号码">
        <van-button size="small" type="primary">发送验证码</van-button>
      </ListItem>
      <ListItem title="验证码">
        <input type="text" slot="value" placeholder="请输入验证码">
      </ListItem>
      <ListItem title="新密码">
        <input type="text" slot="value" placeholder="请输入新密码">
      </ListItem>

      <ListItem title="确认密码" :borderBottom="borderBottomStatus">
        <input type="text" slot="value" placeholder="再次输入新密码">
      </ListItem>
      <Button @handleButtonClick="handleSubmitClick" class="submit-btn">更改</Button>
    </div>
  </div> -->
  <div class="configpassword-wrapper">
    <van-form @submit="onSubmit">
      <div class="input-item">
        <van-field v-model="mobile" :rules="[{ required: true, message: '请输入你的手机号码' }]" label="手机号" placeholder="请输入你的手机号码" />
      </div>
      <div class="input-item">
        <van-field center clearable v-model="code" label="短信验证码" placeholder="请输入短信验证码" :rules="[{ required: true, message: '请输入验证码' }]">
          <van-button slot="button" size="small" type="info" @click="getCode">发送验证码</van-button>
        </van-field>
      </div>
      <div class="input-item">
        <van-field v-model="newPass" :rules="passwordRules" label="设置密码" placeholder="6位以上字母数字组合" />
      </div>
      <div class="config-btn">
        <van-button block type="info" native-type="submit">更改</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
// import ListItem from '@/components/ListItem'
// import Button from '@/components/Button'

// import { getMessage, updatePassWord } from '@/api/my'

// export default {
//   name: 'ModifyPassword',
//   components: {
//     ListItem,
//     Button
//   },
//   data() {
//     return {
//       borderBottomStatus: false,
//     }
//   },
//   methods: {
//     handleSubmitClick() {

//     }
//   }
// }
import Vue from "vue";
import { Field } from "vant";
import { Form } from "vant";
import { Toast } from "vant";
import { Button } from "vant";

import "vant/lib/button/style";
import "vant/lib/toast/style";
import "vant/lib/form/style";
import "vant/lib/field/style";
Vue.use(Field);
Vue.use(Toast);
Vue.use(Form);
Vue.use(Button);
import { mapActions } from 'vuex'
import { getMessage, updatePassWord } from '@/api/my'
import { prodUrl } from '@/config/env'
  import { encrypt } from '@/utils/rsaEncrypt'


export default {
  data() {
    this.passwordRules = [
      { required: true, message: "请输入密码" },
      { validator: this.passwordValidator, message: "6位以上字母或数字组合" }
    ];

    return {
      mobile: "",
      code: "",
      newPass: "",
    };
  },
  methods: {
    ...mapActions('user', ['setToken', 'setUser']),

    passwordValidator(val) {
      return /^[a-zA-Z0-9_-]{6,30}$/.test(val);
    },

    onSubmit() {
      let params = {
        newPass: encrypt(this.newPass),
        code: this.code,
        mobile: this.mobile
      };

      updatePassWord(params)
        .then(res => {
          // let param = {
          //   openid: get("user_openId")
          // };
          Toast.success("修改成功");
          // unbind_weixin(param).then(res => {
          //   remove("user_openId");
          //   Toast.success(res.msg);
          //   this.$store.commit("SET_USERINFO", null);
          //   removeToken();
          //   let path = `/login?type=${this.userIdentity}`;
          //   this.$router.push(path);
          // });

          this.setUser(null)
          this.setToken(null)
          setTimeout(() => {
            // window.location.href = prodUrl + 'my'
            window.location.href = prodUrl
          }, 1000)
        })

        .catch(err => {
          Toast.fail("修改失败");
        });
    },
    getCode() {
      if (this.mobile !== "") {
        getMessage({
          mobile: this.mobile
        })
          .then(res => {
            Toast.success("发送成功");

            this.countDown(60);
          })
          .catch(err => {
            Toast.fail(err.data.detail);
          });
      } else {
        Toast.fail("请先输入手机号！");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
// @import '../../style/mixin';
// .my-wrapper {
//   @include wh(100%, 100%);
//   background-color: #F1F1F1;
//   box-sizing: border-box;
//   padding-top: 1rem;
//   .my-list-wrapper {
//     @include wh(100%, 9rem);
//     background-color: white;
//     input {
//       width: 100%;
//       height: 100%;
//     }
//     .qrcode {
//       position: relative;
//       input {
//         box-sizing: border-box;
//         padding-right: 3rem;
//       }
//       span {
//         position: absolute;
//         right: 1rem;
//         top: 0.2rem;
//         color: #4A8CFF;
//         font-size: 0.9rem;
//       }
//     }
//     .submit-btn {
//       width: 90%;
//       margin: 1rem auto;
//     }
//   }
// }
@import '../../style/mixin';
.configpassword-wrapper {
  background: #fff;
  @include wh(100%, 100%);
  padding-top: 0.8rem;
  padding-left: 0.8rem;
  padding-right: 0.7rem;

  .input-item {
    border-bottom: 1px solid rgba(204, 204, 204, 0.5);
    position: relative;
    .van-cell {
      padding-left: 0px;
    }
    input {
      display: inline-block;
      background: white !important;
      box-sizing: border-box;
      padding: 0 2rem;
      font-size: 0.7rem;
      color: #cccccc;
    }
  }
  .forgetpassword {
    margin-top: 0.7rem;
    text-align: right;
    span {
      color: #666;
      font-size: 0.7rem;
    }
  }
  .config-btn {
    @include wh(17.2rem, 2.2rem);
    margin: 2rem auto;
    background: #3a91f0;
    color: #fff;
    font-size: 0.7rem;
    text-align: center;
    line-height: 2.2rem;
  }
}
</style>
