import axios from '@/config/axios'

// 获取我的信息
export const getInfo = () => axios({
  method: 'get',
  url: `/mobile/my`
})

// 绑定微信
export const getBindWechat = (params) => axios({
  method: 'get',
  url: `/mobile/mp/bindWechat`,
  params
})

// 解绑微信
export const getUnbindWechat = (params) => axios({
  method: 'get',
  url: `/mobile/mp/unbindWechat`,
  params
})


// 发送手机验证码
export const getMessage = (data) => axios({
  method: 'post',
  url: `/message`,
  data
})
// 修改密码
export const updatePassWord = (data) => axios({
  method: 'post',
  url: `/mobile/userinfo/updatePass`,
  data
})
